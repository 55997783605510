.main.contact h1::before {
  background-image: url("/assets/images/polygon-letter2.svg");
  width: 1.35em;
  height: 1.3em;
  top: calc(50% - 0.63em);
  left: calc(50% - 2.05em);
}

#contact-header {
  background-color: var(--lightergreen);
  text-align: center;
  margin: -2.2em auto 0;
  padding-top: 4.7em;
  padding-bottom: 4.4em;
}

.vcard-wrapper {
  mask-image: url("/assets/images/polygon3.svg");
  width: 354px;
  height: 346px;
  margin: 1.3em auto 0;
  mask-size: contain;
  max-width: calc(100% - 1em);

  // padding: 5.6em;
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .vcard {
    padding-top: 0.8em;
  }
}

address.adr {
  font-style: normal;

  .org {
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.1em;
    letter-spacing: 0.06em;
    display: inline-block;
    margin-bottom: 1em;
  }

  .locality,
  .country-name {
    text-transform: uppercase;
  }
}

.vcard .tel {
  display: inline-block;
  margin-top: 1em;
  color: var(--darkgreen);
}

.contact-subtitle {
  @include font-hilight;

  max-width: 856px;
  margin: 0 auto;
  padding: 2.3em 1em 1.62em;
}

.contact-form {
  text-align: center;
  padding-bottom: 12.5em;
}

.contact-form form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 856px;
  text-align: left;
}

@include media(1130px) {
  .contact-form {
    padding-bottom: 10.13em;
  }
}

@include media(960px) {
  .contact-form {
    max-width: calc(100% - 2em);
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 8.82em;
  }
}

@include media(880px) {
  #contact-header {
    margin-top: 0;
    padding-top: 1.5em;
    padding-bottom: 8.21em;
  }
}

@include media(720px) {
  .contact-form {
    padding-bottom: 6.99em;
  }
}

@include media(500px) {
  .vcard-wrapper {
    mask-image: initial;
    background-color: transparent;
    height: auto;
  }

  .contact-form {
    padding-bottom: 5.3em;
  }
}
