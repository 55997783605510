form {
  & > .form-control {
    width: 100%;
  }

  .caption {
    margin-bottom: 1.75em;
    font-size: 0.875em;
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .row .col {
    width: 48.6%;
  }

  .form-control {
    // display: flex;
    // flex-flow: column;
    width: 100%;
    margin-bottom: 1.8em;

    label,
    .form-label,
    input[type="text"],
    input[type="email"],
    input[type="tel"] {
      width: 100%;
    }

    &.hidden {
      display: none;
    }
  }

  input[type="radio"] + label,
  input[type="checkbox"] + label {
    display: inline;
    width: auto;
  }

  label,
  .form-label {
    @include font-cta;

    margin-bottom: 0.7em;
    display: inline-block;

    &.form-check-label {
      font-weight: normal;
      text-transform: none;
      letter-spacing: normal;
    }
  }

  textarea {
    width: 100%;
    padding: 8px 9px 8px 11px;
    min-height: 216px;

    &:focus {
      border: 1px solid var(--green) !important;
      outline: none !important;
      border-radius: 2px;
      padding: 8px 9px 8px 11px;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    padding: 8px 9px 8px 11px;

    &:focus {
      border: 1px solid var(--green) !important;
      outline: none !important;
      border-radius: 2px;
      padding: 9px 9px 9px 11px;
    }
  }

  input[type="checkbox"] {
    display: inline-block;
    accent-color: #1e7363;

    &:hover {
      filter: brightness(1.2);
    }
  }

  .form-select {
    border: 1px solid var(--lightgreen);
    background-color: var(--lightergreen);
    border-radius: 2px;
    padding: 8px 9px 8px 11px;

    &:focus {
      outline: none !important;
    }
  }

  .ss-main .ss-arrow {
    background: url("/assets/images/select.svg") center center no-repeat;
    width: 10px;
    height: 6px;

    path {
      display: none;
    }
  }
}

.invalid-feedback {
  color: #e20051;
  font-size: 0.875em;
  position: absolute;
  margin-top: 2px;
}

.form-control.form-control-invalid {
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    border: 1px solid #e20051 !important;
  }
}

input[type="radio"]::after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"]:checked::after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: #1e7363;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

a.btn,
button,
input[type="submit"] {
  display: inline-block;
  text-align: center;
  border: none;
  border-radius: 2px;
  background-color: var(--green);
  color: white !important;

  @include font-cta;

  text-decoration: none;
  line-height: 1.39em;
  padding: 0.9em 2em 0.8em;
  margin-top: 1.3em;

  &:hover {
    background-color: var(--darkgreen);
  }

  &:active {
    background-color: var(--grey);
  }
}

a.btn {
  margin: 1em auto;
  padding: 0.9em 1.3em 0.8em;
}

.alert-success {
  margin: 1.5em auto;
}

@include media(720px) {
  a.btn {
    margin: 0.8em auto;
    padding: 1.2em 1.3em 1.1em;
  }
}

@include media(500px) {
  form .row {
    flex-direction: column;

    .col {
      width: 100%;
    }
  }
}
