.main.history h1::before {
  background-image: url("/assets/images/polygon-letter3.svg");
  width: 1.31em;
  height: 1.31em;
  top: calc(50% - 0.65em);
  left: calc(50% - 3.29em);
}

html[lang="en"] .main.history h1::before {
  left: calc(50% - 2.36em);
}

#history-past {
  margin: 5.6em auto 3em;
  max-width: 930px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.history-past-image {
  mask-image: url("/assets/images/polygon0.svg");
  display: inline-block;
  width: 406px;
  vertical-align: middle;
}

.history-past-content {
  display: inline-block;
  width: 49%;
  padding-right: 3em;
  vertical-align: middle;
  text-align: left;
}

#history-dates {
  margin: 3em auto;
  width: 862px;
  text-align: left;
  max-width: 85%;

  @include links-regular;

  ul {
    @include list-discreet;

    flex-flow: row wrap;
    place-content: flex-start space-between;
    align-items: flex-start;

    li {
      width: 49%;
      padding-right: 1.7em;
      padding-left: 0.1em;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 1.7em;

      &:nth-child(even) {
        padding-right: 0;
        padding-left: 1em;
      }

      h3 {
        color: var(--darkgreen);
      }
    }
  }
}

blockquote {
  position: relative;

  @include block;

  background: url("/assets/images/quote.jpg") center center no-repeat;
  background-size: cover;
  padding: 12.5% 15.2%;
  margin-top: 6.8em;

  p {
    position: relative;

    @include font-hilight;

    line-height: 1.27;
    padding: 2.9em 1.4em 3.1em;
    background-color: white;

    &::before {
      content: "";
      position: absolute;
      top: 1.7em;
      left: calc(50% - 13px);
      width: 26px;
      height: 15px;
      background: url("../../images/ornement.svg") center center no-repeat;
      background-size: 26px 15px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 1.7em;
      left: calc(50% - 13px);
      width: 26px;
      height: 15px;
      background: url("../../images/ornement.svg") center center no-repeat;
      background-size: 26px 15px;
      transform: scaleY(-1);
    }
  }
}

#history-today {
  font-size: 1em;
  margin: 8.4em auto;
  width: 962px;
  max-width: 80%;
  text-align: left;

  figure {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    img {
      mask-image: url("/assets/images/polygon2.svg");
      width: 416px;
      height: 416px;
    }
  }

  figcaption {
    padding: 0 5em 0 3.2em;

    p {
      margin: 1em auto;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 1.6em;
      }
    }
  }
}

#history-commitments {
  background-color: var(--lightergreen);
  text-align: left;

  & > div {
    font-size: 1em;
    margin: 8.4em auto 0;
    padding: 8.6em 0 10em;
    width: 963px;
    max-width: 80%;

    figure {
      display: flex;
      flex-direction: row;

      .history-commitments-wrapper {
        position: relative;
        margin: 2.5em 0.3em 0 -0.3em;
        min-width: 308px;
        height: 297px;

        img {
          width: 100%;
        }

        .history-commitments-arrows {
          position: absolute;
          top: 0;
          z-index: 1;
          animation: rotatingpolygon 20s linear 0s infinite;
          animation-direction: reverse;
        }
      }
    }

    figcaption {
      padding: 0 3.4em 0 4.8em;

      p {
        margin: 1em auto;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 1.6em;
        }
      }
    }
  }
}

@include media(1130px) {
  #history-past {
    margin-top: 4.48em;

    .history-past-image {
      width: 338px;
      margin: 0 auto;
    }
  }

  blockquote {
    padding: 10% 12.5%;
  }

  #history-today {
    margin: 6.62em auto;
  }

  #history-today figure {
    img {
      width: 345px;
      height: 345px;
    }
  }

  #history-commitments > div {
    margin: 6.6em auto 0;
    padding: 6.6em 0 7.69em;

    .history-commitments-wrapper {
      min-width: 273px;
    }

    figcaption {
      padding-right: 0;
    }
  }
}

@include media(960px) {
  #history-past {
    margin-top: 3.86em;

    .history-past-image {
      width: 300px;
    }
  }

  blockquote {
    padding: 8.67% 11%;
  }

  #history-today {
    width: 611px;
    margin: 5.64em auto;
  }

  #history-today figure {
    flex-direction: column;
    align-items: center;

    img {
      width: 306px;
      height: 306px;
    }
  }

  #history-today figcaption {
    text-align: center;
    padding: 1em 0 0;
  }

  #history-commitments > div {
    width: 611px;
    margin: 5.6em auto 0;
    padding: 5.6em 0 9em;

    figcaption {
      text-align: center;
      padding: 1em 0 0;
    }

    figure {
      flex-direction: column;
      align-items: center;

      .history-commitments-wrapper {
        min-width: 252.85px;
        height: auto;
      }
    }
  }
}

@include media(720px) {
  #history-past {
    margin-top: 3em;

    .history-past-image {
      width: 248px;
      margin-bottom: 1em;
    }
  }

  #history-dates ul li,
  #history-dates ul li:nth-child(even) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 12%;
    text-align: center;
  }

  blockquote {
    padding: 6.76% 9%;
  }

  #history-today {
    margin: 4.26em auto;
  }

  #history-today figure img {
    width: 251px;
    height: 251px;
  }

  #history-commitments > div {
    margin: 4.3em auto 0;
    padding: 4.3em 0 7em;

    .history-commitments-wrapper {
      min-width: 225px;
    }
  }
}

@include media(500px) {
  #history-past {
    margin-top: 2.2em;

    .history-past-image {
      width: 200px;
    }
  }

  #history-dates {
    max-width: 100%;
  }

  #history-dates ul li,
  #history-dates ul li:nth-child(even) {
    padding: 0 7%;
  }

  blockquote {
    padding: 5% 7%;
  }

  #history-today {
    margin: 3em auto;
  }

  #history-today figure img {
    width: 200px;
    height: 200px;
  }

  #history-commitments > div {
    margin: 3em auto 0;
    padding: 3em 0 5em;

    .history-commitments-wrapper {
      min-width: 200px;
    }
  }
}
