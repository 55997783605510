html,
body {
  height: 100%;
  text-rendering: optimizelegibility;
}

.wrapper {
  margin: 0 auto;
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content-zone {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}

#content {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

a,
a:visited {
  color: inherit;
}

a[target="_blank"]::after {
  content: " ↗";
  font-size: 0.6em;
  vertical-align: text-top;
}

.block {
  @include block;
}

main {
  text-align: center;
}

img,
picture {
  height: auto;
}

p {
  margin: 1.5em auto;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
