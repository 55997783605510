@mixin link-discreet {
  color: inherit;
  text-decoration: none;
}

@mixin link-discreet-active {
  color: inherit;
  text-decoration: underline;
}

@mixin links-regular {
  a {
    color: var(--darkgreen);
    text-decoration: underline;
    text-decoration-color: var(--lightgreen);

    &:visited {
      color: var(--darkgrey);
      text-decoration-color: var(--lightgrey);
    }

    &:hover {
      color: var(--darkgreen);
      text-decoration: underline;
      text-decoration-color: var(--darkgreen);
    }
  }
}

@mixin list-discreet {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

@mixin font-title-light {
  font-family: granville, serif;
  font-weight: 300;
  font-style: normal;
}

@mixin font-title-regular {
  font-family: granville, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
}

@mixin font-body-semibold {
  font-family: new-hero, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin font-hilight {
  @include font-title-light;

  font-style: italic;
  font-size: 1.875em;
  color: var(--darkgreen);
  text-align: center;

  @include media(1130px) {
    font-size: 1.63em;
  }

  @include media(720px) {
    font-size: 1.125em;
  }
}

@mixin font-cta {
  @include font-body-semibold;

  text-transform: uppercase;
  font-size: 0.875em;
  letter-spacing: 0.06em;

  @include media(1130px) {
    font-size: 0.8125em;
  }

  @include media(720px) {
    font-size: 0.75em;
  }
}

@mixin font-surtitle {
  @include font-body-semibold;

  text-transform: uppercase;
  letter-spacing: 0.06em;

  @include media(1130px) {
    font-size: 0.9375em;
  }

  @include media(720px) {
    font-size: 0.8125em;
  }
}

@mixin font-xl {
  @include font-title-light;

  font-size: 2.25em;
  letter-spacing: 0;
  line-height: 1.3em;

  @include media(1130px) {
    font-size: 1.8125em;
    line-height: 1.25em;
  }

  @include media(720px) {
    font-size: 1.375em;
    line-height: 1.2em;
  }
}

@mixin block {
  font-size: 1em;
  margin: 1em 4.5em;

  @include media(720px) {
    margin: 1em 2em;
  }

  @include media(720px) {
    margin: 1em 1.5em;
  }

  @include media(500px) {
    margin: 1em;
  }
}

@mixin media($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}
