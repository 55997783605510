@media print {
  nav,
  a.btn,
  a.mobile-nav,
  .mobile-overlay,
  .offer-block small,
  form,
  .footer-left,
  .footer-center,
  .footer-right li:last-of-type,
  .picto-tel,
  h1::before {
    display: none !important;
  }

  header.block {
    position: relative;
  }

  .wrapper {
    margin-top: 0;
  }

  .footer-content {
    display: block;
    margin-bottom: 0;
    text-align: left;
    width: 100%;
  }

  footer .footer-credits {
    width: 100%;
  }

  section {
    page-break-after: always;

    &:last-of-type {
      page-break-after: auto;
    }
  }
}
