.main.projects h1::before {
  background-image: url("/assets/images/polygon-letter4.svg");
  width: 1.33em;
  height: 1.33em;
  top: calc(50% - 0.67em);
  left: calc(50% - 3.76em);
}

html[lang="en"] .main.projects h1::before {
  left: calc(50% - 3.53em);
}

ul.projects {
  @include list-discreet;
  @include block;

  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  height: 2500px;

  &::before,
  &::after {
    content: "";
    flex-basis: 100%;
    width: 0;
    order: 2;
  }
}

ul.projects li {
  position: relative;
  width: calc(33% - 14px);
  margin-bottom: 24px;

  &:nth-child(3n + 1) {
    order: 1;
  }

  &:nth-child(3n + 2) {
    order: 2;
  }

  &:nth-child(3n) {
    order: 3;
  }

  small {
    margin: 0.31em auto 1em;
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1em;
    display: flex;
    flex-flow: column-reverse wrap;
    place-content: center center;
    background-color: var(--darkgreenalpha);
    color: white;
    opacity: 0;
    transition: opacity 0.25s;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s;

      .ico {
        transition: transform 0.3s;
        transform: rotate(90deg);
      }
    }

    p {
      @include font-surtitle;
    }

    h2 {
      @include font-xl;

      text-align: center;
      margin: 0.23em auto 0;
    }

    .ico {
      width: 58px;
      height: 58px;
      display: inline-block;
      margin: 4px auto;
      background: url("/assets/images/polygon-plus.svg");
      background-size: contain;
      transform: rotate(0deg);
      margin-top: 0.31em;
    }
  }
}

body.project {
  display: flex;
  flex-wrap: wrap;
  place-content: center center;
  max-height: calc(100vh - 33px);
  overflow: hidden;
}

.project figure img {
  max-height: calc(100vh - 125px);
  height: auto;
  width: auto;
  margin: 0 auto;
}

.project figcaption {
  display: flex;
  flex-direction: column-reverse;
  padding: 1.8em 1em 1em;

  h1 {
    @include font-xl;

    margin-top: 0.25em;
    text-transform: initial;

    &::before {
      display: none;
    }
  }

  small {
    margin-top: 1em;
  }

  p {
    @include font-surtitle;
  }
}

.project .btn-next,
.project .btn-previous {
  position: absolute;
  top: calc(50% - 29px);
  right: 0;
  width: 58px;
  height: 58px;
  background: url("/assets/images/polygon-next.svg") center center no-repeat;
  font-size: 0;

  &:hover {
    background-image: url("/assets/images/polygon-next2.svg");
  }

  &.btn-previous {
    right: auto;
    left: 0;
    transform: scaleX(-1);
  }
}

@include media(1130px) {
  .contact-form {
    max-width: calc(100% - 2em);
    margin-left: auto;
    margin-right: auto;
  }

  ul.projects {
    height: 1950px;

    li {
      width: calc(33% - 11px);
      margin-bottom: 19px;

      figcaption .ico {
        width: 49px;
        height: 49px;
      }
    }
  }
}

@include media(960px) {
  .contact-form {
    max-width: calc(100% - 2em);
    margin-left: auto;
    margin-right: auto;
  }

  ul.projects {
    height: 1400px;

    li {
      width: calc(33% - 9px);
      margin-bottom: 16px;

      figcaption {
        font-size: 0.8em;
      }

      figcaption .ico {
        width: 44px;
        height: 44px;
      }
    }
  }

  .project figcaption small {
    margin-top: 0.75em;
  }
}

@include media(720px) {
  #contact-header {
    margin-top: 0;
    padding-top: 1.5em;
    padding-bottom: 2.4em;
  }

  ul.projects {
    height: 1250px;

    li {
      width: calc(33% - 6px);
      margin-bottom: 11px;

      figcaption {
        font-size: 0.75em;
      }

      figcaption .ico {
        width: 37px;
        height: 37px;
      }
    }
  }

  .project figcaption small {
    margin-top: 0.5em;
  }

  .project .btn-next,
  .project .btn-previous {
    top: auto;
    bottom: 0;
  }
}

@include media(500px) {
  .vcard-wrapper {
    mask-image: initial;
    background-color: transparent;
    height: auto;
  }

  ul.projects {
    height: 800px;

    li {
      width: calc(33% - 4px);
      margin-bottom: 8px;

      figcaption {
        h2,
        p,
        small {
          display: none;
        }
      }

      figcaption .ico {
        width: 30px;
        height: 30px;
      }
    }
  }
}
