main header {
  margin-top: 5.3em;
  margin-bottom: 4.4em;

  p,
  p:first-of-type,
  p:last-of-type {
    font-size: 1em;
    margin: 1em auto;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 906px;
    margin-top: 0.4em;
    line-height: 1.3;

    @include font-hilight;
  }
}

@include media(1130px) {
  main header {
    margin: 4.64em auto 4em;
  }

  main header p {
    max-width: 410px;
  }
}

@include media(720px) {
  main header {
    margin: 3.3em auto;
  }

  main header p {
    max-width: 410px;
  }
}
