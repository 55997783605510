:root {
  --fancybox-bg: white;
  --f-button-svg-width: 58px;
  --f-button-svg-height: 58px;
  --f-button-width: 58px;
  --f-button-height: 58px;
}

/* stylelint-disable selector-class-pattern */
.fancybox__content {
  padding: 40px;
}

.fancybox__content > .f-button.is-close-btn {
  right: 40px;
  top: 40px;
  width: 58px;
  height: 58px;
  background: url("/assets/images/polygon-close.svg") center center no-repeat;
  background-size: contain;
  opacity: 1;

  &:hover {
    transform: rotate(45deg);
    background: url("/assets/images/polygon-close2.svg") center center no-repeat;
  }

  svg {
    display: none;
  }
}

.fancybox__backdrop {
  background-color: white;
}

.fancybox__container:not(.is-compact) .fancybox__slide.has-close-btn {
  padding: 0;
}

.has-iframe .fancybox__content,
.has-map .fancybox__content,
.has-pdf .fancybox__content {
  height: 100%;
  width: 100%;
}

.fancybox__slide::before,
.fancybox__slide::after {
  display: none;
}

.f-carousel__viewport {
  margin-left: 50%;
  margin-bottom: 2.75em;
  overflow: visible;
}

li.is-current .f-carousel__dot {
  transform: scale(1.7);
}

@include media(960px) {
  li .f-carousel__dot {
    transform: scale(0.9);
  }

  li.is-current .f-carousel__dot {
    transform: scale(1.5);
  }
}

@include media(720px) {
  li .f-carousel__dot {
    transform: scale(0.75);
  }

  li.is-current .f-carousel__dot {
    transform: scale(1.3);
  }
}

@include media(1130px) {
  .fancybox__content > .f-button.is-close-btn {
    width: 50px;
    height: 50px;
  }
}

@include media(960px) {
  .fancybox__content > .f-button.is-close-btn {
    width: 47px;
    height: 47px;
  }
}

@include media(720px) {
  .is-compact .fancybox__content > .f-button.is-close-btn {
    width: 41px;
    height: 41px;
    top: 1em;
    right: 1em;
  }

  .fancybox__content {
    padding: 1.25rem;
  }
}

@include media(500px) {
  .fancybox__content > .f-button.is-close-btn {
    width: 36px;
    height: 36px;
  }

  .fancybox__content {
    padding: 1rem;
  }
}
/* stylelint-enable selector-class-pattern */
