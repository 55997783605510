#home-hero {
  text-align: center;

  figure {
    background: url("../../images/index/header.jpg") center center no-repeat;
    background-size: cover;
    height: 604px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 9.3em;
  }

  figcaption {
    background: url("/assets/images/polygon1.png") center center no-repeat;
    background-size: contain;

    // background-size: 480px 462px;
    width: 505px;
    height: 486px;

    // background-color: var(--lightergreen);
    color: var(--darkgreen);
    margin-right: 2.5em; /* 2.7em; */
    margin-bottom: -4.5em;

    // box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);

    span {
      display: inline-block;
      margin-top: 5.1em;
    }

    & > img {
      margin: -1.5em auto 0.9em;
    }

    h3 {
      font-size: 3.75em;
      padding: 0 1.4em;
      line-height: 1.03;
      letter-spacing: -0.01em;
    }
  }

  p {
    @include font-xl;

    line-height: 1.33em;
    padding: 0 6em;
  }
}

#home-links {
  margin-top: 5.76em;
  margin-bottom: 8.5em;

  ul {
    width: 83.1%;

    @include list-discreet;

    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    list-style-image: none;
    display: flex;
    justify-content: space-between;
  }
}

.home-link {
  position: relative;
  width: 48.88%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  a {
    @include link-discreet;

    .home-link-back {
      transition: transform 200ms ease-out;
      width: 100%;
      z-index: 0;
    }

    &:hover .home-link-back {
      transform: scale(1.1);
    }
  }

  .cartel {
    position: absolute;
    left: calc(50% - 85px);
    top: calc(50% - 85px);
    height: 170px;
    width: 170px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;

    @include font-surtitle;

    .back {
      position: absolute;
      border: 1px solid var(--grey);
      border-radius: 50%;
      width: 93%;
      height: 93%;
    }

    span {
      position: absolute;
      left: calc(50% - 9px);
      top: calc(50% + 17px);
      width: 19px;
      height: 9px;
      background: url("../../images/longarrow.svg") center center no-repeat;
      background-size: 19px 9px;
    }
  }
}

#home-clients {
  background-color: var(--darkgreen);
  color: white;
  padding: 7.6em 0 7.2em;

  .content-zone {
    text-align: center;
  }

  h2 {
    letter-spacing: 0;
  }
}

.clients {
  margin: 4.1em 3.7em 0;

  // max-width: 90%;
  overflow: hidden;
}

.f-carousel {
  padding-bottom: 1.8em;
}

/* stylelint-disable selector-class-pattern */
.f-carousel__slide {
  width: calc(100% / 6);
  padding: 0 12px;

  img {
    width: 100%;
    mask-image: url("/assets/images/polygon4.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
  }
}
/* stylelint-enable selector-class-pattern */

#home-history {
  padding: 7.7em 0 7.9em;

  .home-block-content-image {
    mask-image: url("/assets/images/polygon0.svg");
  }
}

.home-block-content {
  display: flex;
  justify-content: space-between;
  width: 83.1%;
  margin: 0 auto;
}

.home-block-content-image {
  position: relative;
  display: inline-block;
  min-width: 39%;
}

.home-block-content-image-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.home-block-content-text {
  display: flex;
  width: 59%;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  padding: 0 7%;

  p {
    @include font-xl;

    line-height: 1.34em;
    letter-spacing: 0.01em;
    margin-bottom: 0.75em;
    margin-top: 0.3em;
  }
}

#contact {
  padding: 7.7em 0 6.7em;
  background-color: var(--lightergreen);
  color: var(--darkgreen);
  overflow: hidden;

  .home-block-content-text {
    width: 53%;
    margin-left: 3.8em;
    padding: 0 7.5%;

    p {
      margin-bottom: 0.8em;
      margin-top: -6px;
    }
  }

  .home-block-content-image-shapes div {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    animation: rotatingpolygon 20s linear 0s alternate;

    &.contact-shape1 {
      background: url("/assets/images/polygon-contact1.svg") center center
        no-repeat;
      background-size: contain;
      width: 64%; // 267px;
      height: 66.6%; // 267px;
      left: 28%; // 117px;
      animation-direction: reverse;
    }

    &.contact-shape2 {
      background: url("/assets/images/polygon-contact2.svg") center center
        no-repeat;
      background-size: contain;
      width: 49.52%; // 206px;
      height: 50.86%; // 206px;
      top: 25.19%; // 102px;
      left: 3.85%; // 16px;
    }

    &.contact-shape3 {
      background: url("/assets/images/polygon-contact3.svg") center center
        no-repeat;
      background-size: contain;
      width: 30.77%; // 128px;
      height: 31.85%; // 129px;
      top: 67.16%; // 272px;
      left: 57.45%; // 239px;
      animation-direction: reverse;
    }
  }
}

@include media(1130px) {
  #home-hero {
    figure {
      height: 510px;
      margin-bottom: 7.65em;
    }

    figcaption {
      width: 505px;
      height: 486px;
    }

    p {
      padding: 0 5em;
    }
  }

  #home-links {
    margin-top: 4.59em;
    margin-bottom: 6.98em;
  }

  .home-link .cartel {
    width: 157px;
    height: 157px;
    left: calc(50% - 78px);
    top: calc(50% - 78px);
  }

  #home-clients {
    padding: 6.28em 0 5.88em;
  }

  /* stylelint-disable selector-class-pattern */
  .f-carousel__viewport {
    margin-bottom: 2em;
  }
  /* stylelint-enable selector-class-pattern */

  .clients {
    margin: 2.88em 2.48em 0;
  }

  #home-history,
  #contact {
    padding: 6.12em 0 5.95em;
  }

  .home-block-content {
    width: 88.67%;

    .home-block-content-image {
      max-width: 302px;
      margin: auto;
    }

    .home-block-content-text {
      width: auto;
      margin-top: 1.1em;

      p {
        line-height: 1.17em;
      }
    }
  }

  #contact .home-block-content-text {
    margin-left: 1.35em;
    padding-left: 3.5%;
  }

  #contact .home-block-content {
    padding: 0 4.89em;
  }
}

@include media(960px) {
  #home-hero {
    figure {
      height: 420px;
      margin-bottom: 6.3em;
    }

    figcaption {
      transform: scale(0.79);
      margin-right: calc(50% - 259px);
      margin-bottom: -6.55em;
    }

    p {
      padding: 0 4.2em;
    }
  }

  #home-links {
    margin-top: 3.94em;
    margin-bottom: 6.15em;
  }

  .home-link .cartel {
    width: 150px;
    height: 150px;
    left: calc(50% - 75px);
    top: calc(50% - 75px);
  }

  #home-clients {
    padding: 5.56em 0 5.16em;
  }

  .clients {
    margin: 2.21em 0 0;
  }
  /* stylelint-disable selector-class-pattern */
  .f-carousel__slide {
    width: 30.46%;
    padding: 0 9.45px;
  }
  .f-carousel__viewport {
    margin-bottom: 1.5em;
  }
  /* stylelint-enable selector-class-pattern */

  #home-history,
  #contact {
    padding: 5.25em 0 4.89em;
  }

  .home-block-content {
    width: 100%;

    .home-block-content-image {
      max-width: 275px;
      margin: auto;
      min-width: 43%;
    }

    .home-block-content-text {
      width: auto;
      margin-top: 1.1em;

      p {
        line-height: 1.17em;
      }
    }
  }

  #contact .home-block-content-text {
    margin-left: auto;
    padding-left: 0;
  }
}

@include media(720px) {
  #home-hero {
    figure {
      height: 330px;
      margin-bottom: 5.6em;
    }

    figcaption {
      transform: scale(0.6);
      margin-right: calc(50% - 253px);
      margin-bottom: -10em;
    }

    p {
      padding: 0 3.4em;
    }
  }

  #home-links {
    margin-top: 3.03em;
    margin-bottom: 4.98em;

    ul {
      flex-direction: column;

      .home-link {
        width: auto;

        &:nth-child(odd) {
          margin-bottom: 1em;
        }
      }
    }
  }

  .home-link .cartel {
    width: 140px;
    height: 140px;
    left: calc(50% - 70px);
    top: calc(50% - 70px);
  }

  #home-clients {
    padding: 4.54em 0 5.16em;

    h2 {
      margin-bottom: 1.1em;
    }
  }

  /* stylelint-disable selector-class-pattern */
  .f-carousel__viewport {
    margin-bottom: 1em;
  }
  /* stylelint-enable selector-class-pattern */

  .clients {
    margin: 1.27em 0 0;
  }

  #home-history,
  #contact {
    padding: 4.02em 0 3.38em;
  }

  #history-past,
  .home-block-content,
  #contact .home-block-content {
    flex-flow: column !important;
    width: 100%;

    .history-past-image,
    .home-block-content-image {
      max-width: 200px;
      margin: auto;
    }

    .history-past-content,
    .home-block-content-text {
      width: auto;
      text-align: center;
      margin-top: 1.1em;
      max-width: 411px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 7%;

      p {
        line-height: 1.17em;
      }
    }
  }

  #contact .home-block-content {
    flex-flow: column-reverse !important;
    padding: 0;

    .home-block-content-image {
      width: 200px;
      min-width: 43%;
    }
  }
}

@include media(500px) {
  #home-hero {
    figure {
      height: 240px;
      margin-bottom: 4.3em;
    }

    figcaption {
      transform: scale(0.44);
      margin-right: calc(50% - 233px);
      margin-bottom: -11.5em;
    }

    p {
      padding: 0 1.3em;
    }
  }

  #home-links {
    margin-top: 2.2em;
    margin-bottom: 3.9em;

    ul {
      width: 100%;
    }
  }

  .home-link .cartel {
    width: 130px;
    height: 130px;
    left: calc(50% - 65px);
    top: calc(50% - 65px);
  }

  #home-clients {
    padding: 3.4em 0 3.2em;
  }

  .clients {
    margin: 0.5em 0 0;
  }

  /* stylelint-disable selector-class-pattern */
  .f-carousel__viewport {
    margin-bottom: 0;
  }

  .f-carousel__slide {
    width: 45%;
    padding: 0 7px;
  }
  /* stylelint-enable selector-class-pattern */

  #home-history,
  #contact {
    padding: 2.9em 0 2em;
  }
}
