/* stylelint-disable selector-class-pattern */
.TODO {
  display: block;
  margin: 1rem auto;
  padding: 1rem;
  background-color: #fdbaa4;
  color: #ff0030;
  font-weight: bold;
}
/* stylelint-enable selector-class-pattern */

/* @include media(500px) {
  header.block,
  main {
    display: none !important;
  } 
} */