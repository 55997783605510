footer {
  color: white;
  background-color: var(--black);
  padding: 5.1% 5% 1.7%;
  font-size: 0.875em;
}

.footer-credits {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -4em;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse wrap;
  margin-bottom: 1.5em;

  ul {
    margin-top: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none;
  }

  .footer-left {
    position: relative;
    padding-left: 109px;
    background: url("../../images/chef-footer.svg") top left no-repeat;
    background-size: 77px 74px;
    width: 32%;
    min-width: 322px;

    h4 {
      /* Recevez nos actualités */
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .alert-success {
      padding-top: 1em;
    }

    form {
      position: relative;
    }

    .form-control {
      position: relative;
      width: 100%;
      padding-top: 1em;
      padding-bottom: 0.45em;

      label {
        position: absolute;
        margin-top: 0.78em;
        text-transform: capitalize;
        z-index: 1;
        display: none;
      }

      input {
        background: none;
        border: none;
        border-bottom: 1px solid var(--grey);
        width: 100%;
        line-height: 1.4em;
        border-radius: 0;
        padding-left: 0;
        padding-right: 2.4em;
        color: white;
        z-index: 2;
        height: 37px !important;

        &:focus {
          border-top: none !important;
          border-left: none !important;
          border-right: none !important;
          outline: none !important;
          line-height: 1.4em !important;
          box-shadow: none !important;
          height: 37px !important;
        }

        &::placeholder {
          font-weight: 400;
          color: white;
          opacity: 1;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      .invalid-feedback {
        margin-top: 0.7em;
        opacity: 0.7;
      }
    }

    input[type="submit"] {
      position: absolute;
      right: 0;
      top: 20px;
      border: 1px solid white;
      border-radius: 50%;
      overflow: hidden;
      background: url("../../images/arrow.svg") center center no-repeat;
      display: inline-block;
      width: 24px;
      height: 24px;
      color: transparent;
      font-size: 0;
      min-width: 0;

      &:hover {
        background-color: white;
        background-image: url("../../images/arrow2.svg");
      }
    }
  }

  .footer-center {
    width: 440px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    height: 81px;
    padding-left: 23px;

    li {
      position: relative;
      margin-top: 0.1em;
      width: 138px;
      margin-bottom: 1.2em;

      &:hover {
        span {
          opacity: 1;
          transition: opacity 0.5s;
        }
      }
    }

    span {
      transition: opacity 0.3s;
      position: absolute;
      border-radius: 50%;
      background-color: white;
      width: 4px;
      height: 4px;
      top: 8px;
      left: -10px;
      opacity: 0;
    }

    li a {
      color: inherit;
      text-decoration: none;
    }
  }

  .footer-right {
    text-align: right;

    ul {
      min-width: 165px;
      text-align: left;
    }

    li {
      margin-bottom: 1.3em;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        span.picto-tel {
          outline: 1px solid white;
          background-image: url("/assets/images/picto-tel2.svg");
        }

        span.picto-in {
          outline: 1px solid white;
          background-image: url("/assets/images/picto-in2.svg");
        }
      }
    }

    a span {
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      background-color: white;
      width: 24px;
      height: 24px;
      margin-right: 5px;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;

      &.picto-tel {
        background: url("/assets/images/picto-tel.svg") center center no-repeat;
      }

      &.picto-in {
        background: url("/assets/images/picto-in.svg") center center no-repeat;
      }
    }

    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.1em;
    }
  }

  .footer-sponsors {
    min-width: calc(100% - 440px);
    display: flex;
    justify-content: flex-end;
    margin: initial;

    ul {
      width: 165px;
      text-align: left;

      li {
        display: inline-block;
        margin-right: 1em;
      }
    }
  }
}

@include media(1130px) {
  .footer-content {
    flex-wrap: wrap;

    .footer-left {
      min-width: 397px;
      margin-bottom: 1.4em;
    }

    .footer-center {
      width: 440px;
      padding-left: 0;
      height: auto;
      margin-bottom: 1.4em;
      order: 4;
    }

    .footer-sponsors {
      order: 3;
    }
  }

  .footer-credits {
    margin-top: -1em;
  }
}

@include media(720px) {
  .footer-content {
    align-items: center;
    flex-flow: column;
    width: 440px;
    margin: auto auto 0;

    .footer-left {
      padding-left: 0;
      padding-top: 97px;
      min-width: 440px;

      h4 {
        font-size: 0.857em;
      }

      order: 1;
    }

    .footer-center {
      order: 2;
    }

    .footer-right {
      min-width: 440px;
      order: 3;
    }

    .footer-sponsors {
      order: 4;
      width: 100%;
      justify-content: flex-end;

      ul {
        width: auto;
      }

      li:last-of-type {
        margin-right: 0;
      }
    }
  }

  footer .footer-credits {
    width: 440px;
    margin: -2.4em auto 1em;
  }
}

@include media(500px) {
  footer {
    padding: 6.1% 4% 7.6%;
  }

  .footer-content {
    width: 100%;
    margin-bottom: 1.1em;

    .footer-left {
      min-width: 100%;
      background-size: 52px 54px;
      padding-top: 67px;

      .form-control {
        padding-top: 1.1em;
      }
    }

    .footer-center {
      width: auto;
      margin-bottom: 2.4em;

      li {
        width: 50%;
        margin-bottom: 1.4em;

        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(2) {
          order: 5;
        }

        &:nth-child(3) {
          order: 4;
        }

        &:nth-child(4) {
          order: 3;
        }

        &:nth-child(5) {
          order: 2;
        }

        &:nth-child(6) {
          order: 6;
        }
      }
    }

    .footer-right {
      min-width: 0;
      width: 100%;
    }
  }

  footer .footer-credits {
    width: auto;
    font-size: 0.857em;
    color: var(--grey);
    margin-top: -4em;
  }
}
