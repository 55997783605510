.simple .wrapper {
  main {
    max-width: 856px;
    margin: 0 auto 10em;
    text-align: left;
    padding: 0 1em;

    h2 {
      margin-top: 2em;
      font-size: 2em;
      line-height: normal;
    }

    p {
      margin: 1.5em auto;
    }

    ul {
      margin: 1.5em auto;
      padding-inline-start: 15px;
    }

    @include links-regular;
  }
}

@include media(1130px) {
  .simple .wrapper main h2 {
    font-size: 1.7em;
  }

  .simple .wrapper main {
    margin-bottom: 8.45em;
  }
}

@include media(960px) {
  .simple .wrapper main {
    margin-bottom: 7.6em;
  }
}

@include media(720px) {
  .simple .wrapper main h2 {
    font-size: 1.5em;
  }

  .simple .wrapper main {
    margin-bottom: 6.4em;
  }
}

@include media(500px) {
  .simple .wrapper main {
    margin-bottom: 5.3em;
  }
}
