@import "https://use.typekit.net/lot3fbz.css";

body {
  font-family: new-hero, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  @include font-title-light;

  font-size: 5.9375em;
  text-align: center;
  text-transform: capitalize;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-size: contain;
    animation: rotatingpolygon 20s linear 0s infinite;
    z-index: -1;
  }

  @include media(1130px) {
    font-size: 4.68em;
  }

  @include media(720px) {
    font-size: 2.125em;
  }
}

@keyframes rotatingpolygon {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

h2,
h3 {
  @include font-title-regular;

  position: relative;
  font-size: 3em;
  line-height: 1.9;
  max-width: 856px;
  margin-left: auto;
  margin-right: auto;

  @include media(1130px) {
    font-size: 2.3125em;
    line-height: 1.7;
  }

  @include media(720px) {
    font-size: 1.625em;
    line-height: 1.5;
  }
}

h2 span {
  position: absolute;
  top: 0;
  left: calc(50% - 13px);
  width: 26px;
  height: 15px;
  background: url("../../images/ornement.svg") center center no-repeat;
  background-size: 26px 15px;

  @include media(1130px) {
    top: -8px;
  }

  @include media(720px) {
    top: -17px;
  }
}

/* Granville Light Italic
font-family: "granville", serif;
font-weight: 300;
font-style: italic;

Granville Regular
font-family: "granville", serif;
font-weight: 400;
font-style: normal;

Granville Italic
font-family: "granville", serif;
font-weight: 400;
font-style: italic;

Granville Bold
font-family: "granville", serif;
font-weight: 700;
font-style: normal;
*/
