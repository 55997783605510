.error h1::before {
  width: 1.34em;
  height: 1.34em;
  top: calc(50% - 0.67em);
  left: calc(50% - 1.58em);
  background-image: url("/assets/images/polygon-letter1.svg");
}

.error .wrapper main {
  max-width: 856px;
  margin: 0 auto 10em;
  padding: 0 1em;
  font-size: 0.875em;
}

@include media(720px) {
  .error .wrapper main {
    font-size: 1em;
  }
}
