.main.offers h1::before {
  width: 1.34em;
  height: 1.34em;
  top: calc(50% - 0.67em);
  left: calc(50% - 2.7em);
  background-image: url("/assets/images/polygon-letter1.svg");
}

html[lang="en"] .main.offers h1::before {
  left: calc(50% - 3em);
}

.offer-block {
  @include block;

  overflow: hidden;
  position: relative;
  text-align: left;
  margin-bottom: 3.7em;

  &:nth-child(odd) {
    text-align: right;

    .offer-block-content {
      right: auto;
      left: 0;
      background-color: var(--lightergreen);
    }

    figure > img {
      left: auto;
      right: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 12.3em;
  }

  & figure {
    width: 100%;
    height: 482px;

    & > img {
      position: absolute;
      max-width: none;
      height: 100%;
      width: auto;
    }
  }

  .offer-block-content {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--lightgrey);
    width: 482px;
    max-width: 73%;
    height: 386px;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;

    h2 {
      line-height: 1.8;
      padding: 0 1em;
    }

    h2.span {
      background-image: url("../../images/ornement-green.svg");
    }

    p {
      margin: 0 auto 1.9em;
      padding: 0 3em;
    }

    a.btn {
      display: inline-block;
      margin: 0.2em auto 0.4em;
      min-width: 187px;

      &.btn-dl {
        width: 371px;
        max-width: 100%;
      }

      &.btn-dl::after {
        content: "";
        display: inline-block;
        width: 14px;
        height: 17px;
        background: url("../../images/picto-dl.svg") center center no-repeat;
      }

      small {
        margin-left: 0.1em;
        margin-right: 0.3em;
        font-weight: normal;
        text-transform: initial;
      }
    }
  }
}

@include media(1130px) {
  .offer-block {
    margin-bottom: 2.54em;

    &:last-of-type {
      margin-bottom: 9.99em;
    }

    figure > img {
      right: 34%;
    }
  }

  .offer-block:nth-child(odd) {
    figure > img {
      left: 34%;
    }
  }
}

@include media(960px) {
  .offer-block {
    margin-bottom: 2.13em;

    &:last-of-type {
      margin-bottom: 8.73em;
    }

    figure > img {
      right: 17%;
    }

    .offer-block-content a.btn.btn-dl::after {
      height: 18px;
      background-size: 22px;
    }
  }

  .offer-block:nth-child(odd) {
    figure > img {
      left: 17%;
    }
  }
}

@include media(720px) {
  .offer-block {
    margin-bottom: 2.5em;

    &:last-of-type {
      margin-bottom: 6.94em;
    }

    figure {
      height: auto;
    }

    figure > img {
      position: relative;
      right: initial;
      left: initial;
      width: 100%;
      height: auto;
    }

    .offer-block-content {
      position: relative;
      margin: 0 auto;
      right: initial;
      left: initial;
      transform: none;
      margin-top: -10%;
      width: 100%;
      max-width: 478px;
      height: auto;
      padding: 3em 1.5em;

      a.btn.btn-dl::after {
        height: 17px;
        background-size: 19px;
      }
    }
  }

  .offer-block:nth-child(odd) {
    figure > img {
      position: relative;
      right: initial;
      left: initial;
      width: 100%;
      height: auto;
    }

    .offer-block-content {
      right: initial;
      left: initial;
    }
  }
}

@include media(500px) {
  .offer-block {
    &:last-of-type {
      margin-bottom: 5.3em;
    }

    .offer-block-content {
      max-width: 100%;
      margin-top: 0;
      height: auto;

      p {
        padding: 0 1em;
      }
    }

    .offer-block-content a.btn.btn-dl {
      width: auto;
      min-width: 210px;

      span {
        display: block;
      }

      small {
        display: inline-block;
        margin-top: 0.2em;
      }
    }
  }
}
