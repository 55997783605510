header.block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 1.6em;
  margin-bottom: 3.1em;
}

.header-logo {
  flex-grow: 1;
  padding-top: 0.1em;

  a {
    display: inline-block;
  }
}

header {
  ul {
    @include list-discreet;

    margin: 0 auto;
    padding: 0;
    padding-top: 1em;
    font-size: 0.875em;
    list-style-type: none;
    list-style-image: none;
    display: flex;

    a {
      @include link-discreet;

      color: inherit;
      text-decoration: none;
    }
  }

  .mobile-nav {
    display: none;
    background: url("/assets/images/picto-nav.svg") center 4px no-repeat;
    width: 18px;
    height: 30px;
    margin-right: -9px;
    font-size: 0;
  }
}

ul.main-nav {
  li {
    position: relative;
    margin: 0 2em 0 0.2em;
    font-weight: 500;
    letter-spacing: 0.07em;
    padding-bottom: 0.3em;

    &:hover {
      span {
        opacity: 0.56;
        bottom: 0;
        transition:
          opacity 0.5s,
          bottom 0.5s;
      }
    }
  }

  span {
    transition:
      opacity 0.3s,
      bottom 0.3s;
    position: absolute;
    border-radius: 50%;
    background-color: var(--black);
    width: 4px;
    height: 4px;
    bottom: -4px;
    left: calc(50% - 2px);
    opacity: 0;
  }

  li.active span {
    opacity: 1;
    bottom: 0;
  }
}

ul.lg-nav {
  li {
    margin-left: 0.25em;
    color: var(--black);

    a {
      opacity: 0.56;
      transition: opacity 0.3s;

      &.active {
        opacity: 1;
      }

      &:hover {
        opacity: 0.9;
        transition: opacity 0.3s;
      }
    }
  }

  li::after {
    display: inline-block;
    content: " | ";
    color: var(--grey);
    margin-right: 0.25em;
    margin-left: 0.15em;
  }

  li:last-of-type::after {
    display: none;
  }
}

.mobile-overlay {
  display: none;
  flex-flow: column;
  position: fixed;
  z-index: 1000;
  background-color: white;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 0;
  padding: 1.2em;
  padding: 1.6em 4em 1.6em 4.6em;
  text-align: center;

  .mobile-overlay-header {
    display: flex;
    justify-content: space-between;

    .header-logo {
      flex-grow: initial;
      text-align: left;
    }
  }

  .mobile-overlay-close {
    background: url("/assets/images/picto-x.svg") center center no-repeat;
    width: 17px;
    height: 17px;
    margin-top: 9px;
    font-size: 0;
  }

  .mobile-overlay-content-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 2em 1em;
    min-height: 430px;
  }

  figure {
    display: inline-block;
    margin: 0 auto 1.2em;

    figcaption span > img {
      width: 94px;
    }

    figcaption > img {
      width: 39px;
      margin: -13px auto 0;
    }
  }

  nav ul {
    @include list-discreet;

    margin: 0 auto;
    padding: 0;
    padding-top: 1em;
    font-size: 0.875em;
    list-style-type: none;
    list-style-image: none;
    display: flex;

    a {
      @include link-discreet;

      color: inherit;
      text-decoration: none;
    }
  }

  ul.main-nav {
    flex-flow: column;
    text-transform: uppercase;

    li {
      margin-bottom: 2.5em;
      margin-right: 0;
    }
  }

  ul.lg-nav {
    justify-content: center;
    padding-top: 0;

    li:first-of-type {
      margin-left: 0;
    }
  }
}

.tabnav {
  position: absolute;
  top: 0;
  font-size: 0.75em;
  z-index: 101;
  text-transform: none;
  font-weight: normal;
  color: var(--darkgreen);
  text-decoration-color: var(--lightgreen);

  a {
    margin-right: 1em;
    opacity: 0;

    &:focus {
      opacity: 1;
      border: none !important;
      outline: none !important;
    }
  }
}

@include media(1130px) {
  header {
    flex-wrap: wrap;
  }

  .header-logo {
    margin-bottom: 0.5em;
    width: 100%;
  }
}

@include media(880px) {
  .wrapper {
    margin-top: 127px;
  }

  header.block {
    background-color: white;
    position: fixed;
    flex-wrap: nowrap;
    align-items: flex-end;
    padding: 1.6em 4.5em;
    margin: 0 auto;
    width: 100%;
    top: 0;
    z-index: 100;

    nav {
      display: none;
    }

    .mobile-nav {
      display: inline-block;
    }
  }

  .header-logo {
    margin-bottom: 0;

    img {
      width: 184px;
    }
  }
}

@include media(720px) {
  .header-logo img {
    width: 153px;
  }

  header.block,
  .mobile-overlay {
    padding: 1.6em 1.5em;
  }
}

@include media(500px) {
  header.block {
    // margin-top: 1.15em;
    padding: 1.6em 1em;
    margin-bottom: 1.8em;
  }

  .mobile-overlay {
    padding: 1.6em 1em;
  }
}
