:root {
  --black: #21272b;
  --darkgrey: #71767e;
  --grey: #b1b5b9;
  --lightgrey: #f0f2f5;
  --green: #64a08e;
  --darkgreen: #1e7363;
  --darkgreenalpha: rgb(30 115 99 / 90%);
  --lightgreen: #bcd1cb;
  --lightergreen: #e8f2ef;
  --tablet1: 1130px;
  --tablet2: 960px;
  --mobile1: 720px;
  --mobile2: 500px;
  --mini: 320px;
}
